import React, {useState, useEffect, useMemo} from 'react';
import { SubmitHandler, useForm} from "react-hook-form";
import axios from 'axios';


interface Inputs {
    name : string,
    phone : string,
    address : string
}

interface modalType {
    setMsg : React.Dispatch<React.SetStateAction<string>>;
    setIsOpen : React.Dispatch<React.SetStateAction<boolean>>;
}

const UserInfo : React.FC<modalType> = ( {setMsg,setIsOpen}) => {
    const Regex =
        {
            number : /^(0|[1-9]\d*)(\.\d+)?$/
        };
    const {
        register,
        handleSubmit,
        watch,
        setFocus,
        reset,
        formState: { errors },
    } = useForm<Inputs>({
        defaultValues: {
            name : '',
            address : '',
            phone : ''
        },
        mode: 'onChange',
    });

    const phoneValue = watch('phone');
    const dashPhone = useMemo(() => {
        if (phoneValue) {
            // 정규식을 사용하여 주민등록번호 일부를 가려줍니다.
            return phoneValue.replace(/[^0-9]/g, '').replace(/[^0-9]/g, '').replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, '$1-$2-$3').replace(/-{1,2}$/g, '');
        }
        return '';
    }, [phoneValue]);

    const onSubmit: SubmitHandler<Inputs> = async (data : any) => {
        try{
                const response = await axios.post('https://insurance-open-api.simg.kr/api/v1/prod/claimRequest', data, {
                    headers: {
                        'X-API-SECRET' : '3C4FDCEA-0715-4D45-B548-BCDB8F1A7750'
                    }
                });
                // @ts-ignore
            if (response.data.code === '200') {
                    alert('정상적으로 접수되었습니다.');
                } else {
                    // @ts-ignore
                setMsg(response.data.msg);
                }
            reset();
        }catch(error){
        setMsg('서비스 오류입니다.')
        }
    }
    const onError = (errors: any) => {
        setIsOpen(true);
        setMsg('');
        if (errors.name) {
            setFocus('name');
            setMsg('이름을 입력해 주세요.');
        }else if(errors.address){
            setFocus('address')
            setMsg('주소지를 입력해주세요.');
        } else if (errors.phone) {
            setFocus('phone')
            setMsg('연락처를 입력해 주세요.');
        }
    };

    return (
        <div className='pt-8  flex_center w-full flex-col pb-[90px] bg-[#f8f9fb]'>
            <div className='section flex-col'>
                <h2 className='title_text'>소송 보험 접수 양식</h2>
            </div>
            <form onSubmit={handleSubmit(onSubmit, onError)} encType="multipart/form-data">
                <div className='pt-8 w-full flex_center flex-col'>
                    <div className='flex flex-col'>
                        <label className='mr-auto text-[16px] font-bold pb-2'>성명</label>
                        <input
                            placeholder={'이름을 입력해 주세요.'}
                            {...register('name', {
                                required: true,
                            })}
                            className="input2"
                        />
                    </div>
                    <div className='flex flex-col mt-8'>
                        <label className='mr-auto text-[16px] font-bold pb-2'>연락처</label>
                        <input
                            value={dashPhone}
                            placeholder={'연락처를 입력해 주세요.'}
                            {...register('phone', {
                                required: true,
                            })}
                            className="input2"
                        />
                    </div>
                    <div className='flex flex-col mt-8 w-full'>
                        <label className='mr-auto text-[16px] font-bold pb-2'>건물 소재지</label>
                        <input
                            placeholder={'건물 소재지를 입력해주세요.'}
                            {...register('address', {
                                required: true,
                            })}
                            className="input2"
                        />
                    </div>
                </div>
                <div className='w-full flex_center'>
                    <button type="submit" className='button_on mt-8'>접수하기</button>
                </div>
            </form>
        </div>
    );
}

export default UserInfo;

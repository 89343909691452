import React from 'react';
import LogoIcon from '../assets/images/favicon-32x32.png';
const Header: React.FC = () => {
    return (
        <div className='header'>
            <div className='flex px-4 items-center w-full h-full'>
                <div className='flex_center cursor-pointer'>
                    <img src={LogoIcon} alt={'logo'} width={32}/>
                    <h2 className='font-bold'>밸류맵 임대차소송보험 접수</h2>
                </div>
            </div>
        </div>
    );
};

export default Header;

import React from 'react';
import {useMediaQuery} from "react-responsive";
import cn from 'classnames';
const Section1: React.FC = () => {
    const isMobile = useMediaQuery({maxWidth : 930})

    const pdfDownload = (e : React.MouseEvent<HTMLButtonElement>) => {
       e.preventDefault();

        const pdfUrl = 'https://insurance-info.simg.kr/임대차보증금 민사소송 법률비용보장 특별약관.pdf';
        // PDF 파일 다운로드 링크 생성
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.setAttribute('download', '임대차보증금 민사소송 법률비용보장 특별약관.pdf');
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    }
    return (
        <div className='flex-col flex items-center w-full py-[90px]'>
            <div className='flex flex-col space-y-8 py-8 px-4'>
                <h2 className={cn('font-bold',{'text-[18px]': isMobile, 'text-[24px]': !isMobile})}>임대차 소송보험이란?</h2>
                <p className={cn('font-semibold',{'text-[12px]': isMobile , 'text-[18px]': !isMobile})}>
                    보험기간 중에 피보험자에게 임대차보증금과 관련된 소송제기의 원인이 되는 사건이 발생하여, <br/>
                    소송사건이 보험기간 중에 대한민국 법원에 제기되어 그 소송이 판결, <br/>
                    소송상 조정 또는 소송상 화해로 종료됨에 따라 피보험자가 부담하는 법률비용을 보상합니다.
                </p>
                <table className='border w-full'>
                    <thead className={cn('text-center bg_base text-white shadow-md font-bold',{'text-[14px]': isMobile, 'text-[20px]': !isMobile})}>
                    <tr>
                        <td className='h-[20px]'>
                            구분
                        </td>
                        <td>
                            내용
                        </td>
                    </tr>
                    </thead>
                    <tbody className={cn('text-center text-[14px]',{'text-[11px]': isMobile, 'text-[16px]': !isMobile})}>
                    <tr className='border-b'>
                        <td className='h-[60px] border-r'>
                            변호사 선임비
                        </td>
                        <td>
                            15,000,000원 한도
                            <p className={cn('',{'text-[9px]': isMobile, 'text-[12px]': !isMobile})}>
                                * 자기부담금 100,000원
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td className='h-[60px] border-r'>
                            인지료 및 송달료
                        </td>
                        <td >
                            5,000,000원 한도
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div className='flex_center'>
                    <button className='border rounded-lg w-[160px] h-[40px] bg_base text-white font-bold' onClick={pdfDownload}>
                        보험약관
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Section1;

import Logo from '../assets/images/logo2.png'
import React, {useState, useEffect} from 'react';
import Modals from '../component/modals';
import axiosInstans from "../api/axiosInstans"
import dayjs from 'dayjs';
import cn from 'classnames';
import {useMediaQuery} from "react-responsive";
import {useSearchParams } from 'react-router-dom';
import Alert from "../component/alert";

interface UserData {
    name: string;
    cmpk: string;
}

interface reservCode {
    code: string;
}

export default function Home(){
    const [searchParams, setSeratchParams] = useSearchParams();
    const [name, setName] = useState('');
    const [cmpk, setCmpk] = useState('');
    const isMobi = /Mobi/i.test(window.navigator.userAgent);
    const isPc = useMediaQuery({ query: "(min-width: 990px)" });
    let today = dayjs().format('YYYY-MM-DD');
    const [common, setCommon] = useState(false);
    const [toDay, setToday] = useState('');
    const [fromDay, setFromDay] = useState('');
    let [open, setOpen] = useState(false);
    const [type, setType] = useState('');
    const [msg, setMsg] = useState('');


    async function reservEvent(event : React.MouseEvent<HTMLButtonElement>){
        try{
            let access = '';
            if(isMobi){
                access = 'Mobile';
            }else {
                access = 'Web';
            }
            let param = {
                toDay : toDay,
                fromDay : fromDay,
                access : access,
                cmpk : cmpk,
            }

            if(fromDay && toDay){
                // @ts-ignore
                await axiosInstans.post('/reservation', param).then((res : AxiosResponse<reservCode> ) => {
                    if('Ok' === res.code){
                        setMsg('접수 완료되었습니다.');
                        setCommon(true);
                    } else {
                        setMsg('');
                        setCommon(true);
                    }
                });
            }else {
                setMsg('상담 가능 일자를 선택해주세요.');
                setCommon(true);
            }
        }catch(e){
            console.log(e);
            setMsg('');
            setCommon(true);
        }
    }

    useEffect(() => {
        if (open) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [open]);

    useEffect(() => {
        let client  = searchParams.get('client');
        let cName  = searchParams.get('cName');
        let join  : any  = searchParams.get('join');
        if(client !== null) client = client.replace(' ','+');
        if(cName !== null) cName = cName.replace(' ','+');
        let param = {
            client : client,
            cName : cName
        }
        if(client && cName){
            // @ts-ignore
            axiosInstans.post('/userInfo', param).then((res: AxiosResponse<UserData>) => {
                setName(res.name);
                setCmpk(res.cmpk);
                sessionStorage.setItem("name", res.name);
                sessionStorage.setItem("cmpk", res.cmpk);
                sessionStorage.setItem("join", join);
            });
        }
    }, []);
    return (
        <div className='w-[calc(100%-20%)] pb-[40px]'>
            <div className='w-full flex_center'>
                <div>
                    <img src={Logo} alt={'logo'} width={isPc ? 100 : 60}/>
                </div>
                <div className={cn('font-bold',{'text-[14px] ml-4' : !isPc, 'text-[24px] ml-16' : isPc})}>
                    <h2>임베디드 보험의 선구자 SIMG</h2>
                </div>
            </div>
            <div className='w-full flex_center border mt-8 border-black flex-col py-8'>
                <div className={cn('text-blue-600 flex_center flex-col')}>
                    <div className={cn('font-bold flex_center flex-col', {
                        'text-[24px]': isPc,
                        'text-[14px] flex': !isPc
                    })}>
                        <div className='border border-black text-black p-2'>
                            <h2>화재보험 가입 신청 안내</h2>
                        </div>
                    </div>
                    <p className={cn('mt-8 text-black', {'text-[12px]': !isPc, 'text-[20px]': isPc})}>
                        {name}님, 화재보험 가입 신청서를 작성하여 주시면
                    </p>
                    <p className={cn('text-black', {'text-[12px]': !isPc, 'text-[20px]': isPc})}>
                        고객님께 최적의 보험상품을 안내해 드리겠습니다.
                    </p>
                </div>
                <a className={isPc ? 'button' : 'm_button'} href={'https://docs.google.com/forms/d/e/1FAIpQLSfBGYu4FoIt-yv-wpa3dwVuF6E-8I6gJMgf_GRUF7_5uD-4lA/viewform'} target={'_blank'} rel="noreferrer">보험 가입 신청</a>
                <a className={isPc ? 'button' : 'm_button'} href={'https://insurance-info.simg.kr/화재보험 상품 및 가입 신청 안내.pdf'} target={'_blank'} rel='noreferrer'>보험 상품 안내</a>
            </div>

            <div className='w-full flex_center border mt-8 border-black flex-col py-8'>
                <div className='flex_center flex-col'>
                    <div>
                        <h2 className={cn('font-bold text-blue-600', {
                            'text-[16px]': !isPc,
                            'text-[24px]': isPc
                        })}>임대차보증금 소송보험 <span className='text-black'>가입완료(무료)</span></h2>
                    </div>
                    <ul className={cn('list-disc my-4', {'text-[12px]': !isPc, 'text-[20px]': isPc})}>
                        <li>
                            보험 가입 증명 및 약관 확인은 아래 버튼을 클릭하세요.
                        </li>
                        <li>
                            피보험자의 정보 변경/해지, 보험금 청구/지급 관련 문의는<br/>
                            SIMG 고객센터로 연락주세요(☎ 1877-3006)
                        </li>
                    </ul>
                    <button className={isPc ? 'button bg-[#c5dcfe]' : 'm_button bg-[#c5dcfe]'}
                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                setType('regi');
                                setOpen(true);
                            }}>보험가입증명서
                    </button>
                    <a className={isPc ? 'button bg-[#c5dcfe]' : 'm_button bg-[#c5dcfe]'} href={'https://insurance-info.simg.kr/임대차보증금법률비용보장보험 안내서.pdf'}
                       target={'_blank'} rel='noreferrer'>보험 상품 안내</a>
                    <button className={isPc ? 'button bg-[#c5dcfe]' : 'm_button bg-[#c5dcfe]'}
                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                setType('pdf');
                                setOpen(true);
                            }}>보험 약관
                    </button>
                    <button className={isPc ? 'button bg-[#c5dcfe]' : 'm_button bg-[#c5dcfe]'}
                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                setType('process');
                                setOpen(true);
                            }}>보험금 청구 및 절차
                    </button>
                </div>
            </div>
            {open && <Modals open={open} setOpen={setOpen} type={type}/>}
            {common && <Alert isOpen={common} setIsOpen={setCommon} msg={msg}/>}
        </div>
    )
}
